import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { InputAdornment, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import { Box, Stack } from "@mui/system";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { changePassword, useValidateResetPasswordTokenRequest } from "src/api/auth";
import FormProvider, { RHFTextField } from "src/components/hook-form";
import Iconify from "src/components/iconify";
import { useBoolean } from "src/hooks/use-boolean";
import { RouterLink } from "src/routes/components";
import * as Yup from "yup";

export default function ResetPasswordView(props: {token: string}) {

    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const password = useBoolean();
    const confirmedPassword = useBoolean();
    const [userData, setUserData] = useState({
        userId: 0,
        token: '',
        password: '',
        confirmedPassword: '',
    });

    const { memoizedValue } = useValidateResetPasswordTokenRequest({token: encodeURIComponent(props.token)});
    
    useEffect(() => {
        if(memoizedValue.error) {
            navigate('/');
        }

        if(memoizedValue.userId) {
            setUserData((prev) => ({
                ...prev,
                userId: memoizedValue.userId,
                token: encodeURIComponent(props.token),
            }));
        }
    }, [memoizedValue])
    

    const FogotPasswordSchema = Yup.object().shape({
        password: Yup.string()
        .required("A senha é obrigatória"),
        confirmedPassword: Yup.string()
        .required("A confirmação da senha é obrigatória"),
      });

    const defaultValues = {
        password: "",
        confirmedPassword: "",
    };

    const methods = useForm({
        resolver: yupResolver(FogotPasswordSchema),
        defaultValues,
    });

    const {
        reset,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = handleSubmit(async (e) => {
        if(!e.password || !e.confirmedPassword) {
            return;
        }
        
        try {
            const { token, userId } = userData;
            const res = await changePassword({
                token,
                userId,
                password: e.password,
                confirmedPassword: e.confirmedPassword,
            });
            if(res) {
                snackbar.enqueueSnackbar('Senha alterada com sucesso!', {variant: 'success'}  )
                navigate('/');
            }
        } catch(e) {
            snackbar.enqueueSnackbar(e.message, {variant: 'error'}  )
        }
    });

    return(
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
        }}>
                <FormProvider methods={methods} onSubmit={onSubmit}>
                    <Stack spacing={2.5} sx={{
                        alignItems: "center",
                        width: {
                            xs: 375,
                            sm: 375,
                            md: 400,
                        },
                        minWidth: 375
                    }} >
                        <Typography variant="h3" sx={{ mb: 1, flexGrow: 1}}>Alteração de senha</Typography>
                        <RHFTextField
                            name="password"
                            label="Senha"
                            type={password.value ? "text" : "password"}
                            InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                <IconButton onClick={password.onToggle} edge="end">
                                    <Iconify
                                    icon={
                                        password.value ? "solar:eye-bold" : "solar:eye-closed-bold"
                                    }
                                    />
                                </IconButton>
                                </InputAdornment>
                            ),
                            }}
                        />
                        <RHFTextField
                            name="confirmedPassword"
                            label="Confirmar senha"
                            type={confirmedPassword.value ? "text" : "password"}
                            InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                <IconButton onClick={confirmedPassword.onToggle} edge="end">
                                    <Iconify
                                    icon={
                                        confirmedPassword.value ? "solar:eye-bold" : "solar:eye-closed-bold"
                                    }
                                    />
                                </IconButton>
                                </InputAdornment>
                            ),
                            }}
                        />
                        <LoadingButton type="submit" variant="contained" loading={isSubmitting} sx={{ ml: 'auto', width: '100%', padding: 1.5 }}>
                            Atualizar senha
                        </LoadingButton>
                        <Stack direction="row" spacing={1} sx={{ width: '100%', justifyContent: 'center' }}>
                            <Iconify icon="eva:arrow-ios-back-fill" />
                            <Link
                                variant="body2"
                                color="inherit"
                                sx={{ alignSelf: "center" }}
                                component={RouterLink} 
                                href={"/login"}
                                >
                                {"Voltar para o login"}
                            </Link>
                        </Stack>
                    </Stack>
                </FormProvider>
        </Box>
    );
}