import { useMemo } from "react";

import { paths } from "src/routes/paths";

import { useTranslate } from "src/locales";

import { useAuthContext } from "src/auth/hooks";
import Iconify from "src/components/iconify";
import SvgColor from "src/components/svg-color";

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon("ic_job"),
  blog: icon("ic_blog"),
  chat: icon("ic_chat"),
  mail: icon("ic_mail"),
  user: icon("ic_user"),
  file: icon("ic_file"),
  lock: icon("ic_lock"),
  tour: icon("ic_tour"),
  order: icon("ic_order"),
  label: icon("ic_label"),
  blank: icon("ic_blank"),
  kanban: icon("ic_kanban"),
  folder: icon("ic_folder"),
  banking: icon("ic_banking"),
  booking: icon("ic_booking"),
  invoice: icon("ic_invoice"),
  product: icon("ic_product"),
  calendar: icon("ic_calendar"),
  disabled: icon("ic_disabled"),
  external: icon("ic_external"),
  menuItem: icon("ic_menu_item"),
  ecommerce: icon("ic_ecommerce"),
  analytics: icon("ic_analytics"),
  dashboard: icon("ic_dashboard"),
  coupon: icon("ic_coupon"),
  machine: <Iconify icon="mdi:coffee-machine" />,
  telemetry: <Iconify icon="carbon:meter"/>,
  visits: <Iconify icon="zondicons:travel-taxi-cab"/>
};

// ----------------------------------------------------------------------

export function useNavData() {
  const refresh = false;

  const { t } = useTranslate();

  const { user } = useAuthContext();

  const data = useMemo(
    () => [
      {
        subheader: t("menu"),
        enabled: true,
        items: [
          {
            title: "Organizações",
            // roles: ["admin", "mannager"],
            permissions: ["organization-write"],
            path: paths.administrativeDashboard.organization.list,
            icon: ICONS.ecommerce,
            onlyAdmin: true,
          },
          {
            title: t("consolidation"),
            path: paths.administrativeDashboard.consolidation.list,
            icon: ICONS.invoice,
            permissions: ["consolidation-view"],
            onlyAdmin: true,
          },
          {
            title: t("statements"),
            path: paths.administrativeDashboard.statement.list,
            icon: ICONS.invoice,
            permissions: ["saas-statement-view"],
            onlyAdmin: true,
          },
          {
            title: t("machines"),
            path: paths.administrativeDashboard.saasMachine.list,
            icon: ICONS.machine,
            permissions: ["saas-machine-view"],
            onlyAdmin: true,
          },
          {
            title: t("sales"),
            path: paths.administrativeDashboard.saasSales.list,
            icon: ICONS.machine,
            onlyAdmin: true,
          },
          {
            title: t("users"),
            path: paths.administrativeDashboard.saasUser.list,
            icon: ICONS.user,
            onlyAdmin: true,
          }
        ],
      }
    ],
    [t]
  );

  return data;
}
