import { ILocationAuthItem } from './locationAuth';

// ----------------------------------------------------------------------

export type IUserTableFilterValue = string | string[];

export type IUserTableFilters = {
  search: string;
  role: string[];
  status: string;
  orderBy: string | undefined; //Aqui esta diferente, nao usar esse padrao de orderBy
  order: 'asc' | 'desc' | undefined;
};

// ----------------------------------------------------------------------

export type IUserListResponse = {
  userList: IUser[];
  userListCount: IUserCount;
  userListLoading: any;
  userListError: any;
}

export type IUserCard = {
  id: string;
  name: string;
  role: string;
  coverUrl: string;
  avatarUrl: string;
  totalPosts: number;
  totalFollowers: number;
  totalFollowing: number;
};

export type IUserItem = {
  id: number;
  name: string;
  email: string;
  phone: string;
  username: string;
  password: string;
  document: string;
  role: {
    name: string;
    code: string;
  }[];
  logoPath: string;
  active: boolean;
  emailConfirmed: boolean;
  isAdministrator: boolean
};

export type IUser = {
  id: number;
  name: string;
  email: string;
  phone: string;
  username: string;
  password: string;
  document: string;
  role: string;
  logoPath: string;
  active: boolean;
  emailConfirmed: boolean;
  isAdministrator: boolean;
  supportToken?: string;
};

export type IUserCount = {
  active: number;
  inactive: number;
  pagination: number;
  all: number;
};

export type IUserRole = {
  name: string;
  code: string;
};

export type IUserAccountChangePassword = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

export const UserTemplate: IUserItem = {
  id: 0,
  name: ``,
  email: ``,
  phone: ``,
  username: ``,
  password: ``,
  document: ``,
  role: [],
  logoPath: ``,
  active: false,
  emailConfirmed: false,
  isAdministrator: false,
}

//Requests
export class IUserSaveRequest {
  username: string;
  name: string;
  password: string;
  email: string;
  // logoPath: string;
  cpf: string;
  phone: string;
  roles: {
    name: string;
    code: string;
  }[];
  emailConfirmed: boolean;
  locations: ILocationAuthItem[];
  constructor(data: any){
    this.username = data.username;
    this.name = data.name;
    this.password = data.password;
    this.email = data.email;
    this.cpf = data.document;
    this.phone = data.phone;
    this.roles = data.role;
    this.emailConfirmed = data.emailConfirmed;
    this.locations = data.locations;
  }
}

export class IUserUpdateRequest {
  id: number;
  username: string;
  name: string;
  email: string;
  // logoPath: string;
  cpf: string;
  phone: string;
  password: string;
  roles: {
    name: string;
    code: string;
  }[];
  rolesToRemove: {
    name: string;
    code: string;
  }[]; 
  emailConfirmed: boolean;
  locations: ILocationAuthItem[];
  constructor(data: any, rolesToRemove: {
    name: string;
    code: string;
  }[]){
    this.id = data.id;
    this.username = data.username;
    this.name = data.name;
    this.email = data.email;
    this.cpf = data.document;
    this.phone = data.phone;
    this.password = data.password;
    this.roles = data.role;
    this.rolesToRemove = rolesToRemove;
    this.emailConfirmed = data.emailConfirmed;
    this.locations = data.locations;
  }
}

export class IUserProfileUpdateRequest {
  id: number;
  username: string;
  name: string;
  email: string;
  cpf: string | null;
  phone: string;
  password: string;
  constructor(data: any){
    this.id = data.id;
    this.username = data.username;
    this.name = data.name;
    this.email = data.email;
    this.phone = data.phone;
    this.password = data.newPassword;
    this.cpf = null;
    if(data.document && data.document.trim() != ''){
      this.cpf = data.document;
    }
  }
}

export type UsersListResponse = {
  usersAuthorized: UserInfo[];
  allUsers: UserInfo[];
}

export type UserInfo = {
  id: number;
  name: string;
}