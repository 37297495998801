import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useSnackbar } from "notistack";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { updateOrganizationConfigurations, useGetOrganizationConfig } from "src/api/organization";
import { useAuthContext } from "src/auth/hooks";
import { RHFSwitch, RHFTextField } from "src/components/hook-form";
import FormProvider from "src/components/hook-form/form-provider";
import Scrollbar from "src/components/scrollbar";
import { useBoolean } from "src/hooks/use-boolean";
import * as Yup from 'yup';

type Props = {
    open: boolean;
    onClose: () => void;
}

export default function OrganizationConfigEdit({ open, onClose }: Props) {
    const { user } = useAuthContext();
    const { enqueueSnackbar } = useSnackbar();
    const refresh = useBoolean(open);
    const { selectedOrganization } = useAuthContext();

    const { configs = [], isLoading }: any = useGetOrganizationConfig(refresh.value, selectedOrganization?.id);

    console.log(configs);
    

    const organizationConfigSchema: any = Yup.object().shape({
        configs: Yup.array().of(
            Yup.object().shape({
                key: Yup.string(),
                value: Yup.string().nullable(),
                description: Yup.string(),
                type: Yup.string().oneOf(['text', 'boolean', 'number', 'currency'], 'Tipo inválido').default('text'),
                active: Yup.boolean().required('Ativo é obrigatório')
            })
        ),
    });

    const defaultValues = useMemo(
        () => ({
            configs: configs?.map((config: any) => ({
                key: config.key || '',
                value: config.value || '',
                description: config.description || '',
                type: config.type || 'text',
                active: config.active || false
            })),
        }),
        [configs]
    );

    const methods = useForm({
        resolver: yupResolver(organizationConfigSchema),
        defaultValues,
    });

    const {
        reset,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    useEffect(() => {
        if (configs) {
            reset(defaultValues);
        }
    }, [configs, reset]);

    const onSubmit = handleSubmit(async (data) => {
        try {
            const request = {
                organizationId: selectedOrganization?.id,
                user: user,
                configs: data.configs,
            };
            
            let response = await updateOrganizationConfigurations(request);

            if(response && response.success){
                enqueueSnackbar('Configurações da organização atualizadas com sucesso!');
                onClose();
                return;
            }
            enqueueSnackbar('Algumas configurações não foram salvar com sucesso, tente novamente', { variant: 'error' });
        } catch (error) {
            console.error(error);
            enqueueSnackbar('Erro ao atualizar configurações.', { variant: 'error' });
        }
    });

    if (isLoading || !configs) {
        return <></>;
    }

    return (
        <Dialog 
            fullWidth 
            open={open} 
            onClose={onClose} 
            PaperProps={{ sx: { m: 2, maxWidth: '400px' } }}
        >
            <FormProvider methods={methods} onSubmit={onSubmit}>
                <DialogTitle sx={{ textAlign: "center" }}>
                    Configurações da Organização
                </DialogTitle>

                <DialogContent>
                    <Scrollbar sx={{ maxHeight: "500px" }}>
                        <Stack spacing={3}>
                            {configs.length > 0 && configs.map((config: any, index: number) => (
                                <Box key={config.key} sx={{ border: `1px solid #eee`, p: 2, borderRadius: '8px' }}>
                                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
                                        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                                            {config.description}
                                        </Typography>
                                        <RHFSwitch
                                            name={`configs[${index}].active`}
                                            label=""
                                        />
                                    </Stack>

                                    {config.type === "boolean" ? (
                                        <></>
                                    ) : (
                                        <RHFTextField
                                            name={`configs[${index}].value`}
                                            label="Valor"
                                            fullWidth
                                            type={config.type === "number" || config.type === "currency" ? "number" : "text"}
                                            sx={{ mt: 1 }}
                                        />
                                    )}
                                </Box>
                            ))}
                        </Stack>
                    </Scrollbar>
                </DialogContent>

                <DialogActions sx={{ justifyContent: "space-between", padding: "16px" }}>
                    <Button variant="outlined" onClick={onClose} sx={{ width: "45%" }}>
                        Cancelar
                    </Button>

                    <LoadingButton 
                        type="submit" 
                        variant="contained" 
                        loading={isSubmitting} 
                        sx={{ width: "45%" }}
                    >
                        Salvar
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
        </Dialog>
    );
}
