import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";
import Link from "@mui/material/Link";
import { Box, Stack, useTheme } from "@mui/system";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { forgotPasswordRequest } from "src/api/auth";
import PasswordIcon from "src/assets/icons/password-icon";
import SentIcon from "src/assets/icons/sent-icon";
import FormProvider, { RHFTextField } from "src/components/hook-form";
import Iconify from "src/components/iconify";
import { RouterLink } from "src/routes/components";
import { hideEmail } from "src/utils/email";
import * as Yup from "yup";

export default function ForgotPasswordView() {

    const navigate = useNavigate();
    const theme = useTheme();
    const [success, setSuccess] = useState(false);
    const [email, setEmail] = useState('');

    const FogotPasswordSchema = Yup.object().shape({
        email: Yup.string()
          .required("O e-mail é obrigatório"),
      });

    const defaultValues = {
        email: "",
    };

    const methods = useForm({
        resolver: yupResolver(FogotPasswordSchema),
        defaultValues,
    });

    const {
        reset,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = handleSubmit(async (e) => {
        forgotPasswordRequest(e).then((res) => {
            setSuccess(true);
            setEmail(e.email);
        }).catch((err) => { 
            navigate('/');
        });
    });

    const form = (
        <FormProvider methods={methods} onSubmit={onSubmit}>
            <Stack spacing={2.5} sx={{
                alignItems: "center",
                mb: 5
            }} >
                <PasswordIcon width={ 96 }/>
                <Typography variant="h3">Esqueceu a senha?</Typography>
                <Typography fontSize={ 14 } align="center" color={theme.palette.text.secondary}>Por favor insira seu email para que possamos enviar um link de redefinição.</Typography>
            </Stack>
            <Stack spacing={2.5} sx={{
                alignItems: "center",
            }} >
                <RHFTextField name="email" label="Email"/>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting} sx={{ ml: 'auto', width: '100%', padding: 1.5 }}>
                    Enviar link
                </LoadingButton>
                <Stack direction="row" spacing={1} sx={{ width: '100%', justifyContent: 'center' }}>
                    <Iconify icon="eva:arrow-ios-back-fill" />
                    <Link
                        variant="body2"
                        color="inherit"
                        sx={{ alignSelf: "center" }}
                        component={RouterLink} 
                        href={"/"}
                        >
                        {"Voltar para o login"}
                    </Link>
                </Stack>
            </Stack>
        </FormProvider>
    );

    const notificationSent = (
        <Stack spacing={2.5} sx={{
            alignItems: "center",
            mb: 5
        }} >
            <SentIcon width={ 96 }/>
            <Typography variant="h3" align="center">A verificação foi enviada via email!</Typography>
            <Typography fontSize={ 14 } align="center" color={theme.palette.text.secondary}>{`
                Nós enviamos um link para o email: ${hideEmail(email)}
            `}</Typography>
            <Stack direction="row" spacing={1} sx={{ width: '100%', justifyContent: 'center' }}>
                <Iconify icon="eva:arrow-ios-back-fill" />
                <Link
                    variant="body2"
                    color="inherit"
                    sx={{ alignSelf: "center" }}
                    component={RouterLink} 
                    href={"/"}
                    >
                    {"Voltar para o login"}
                </Link>
            </Stack>
        </Stack>
    )

    return(
        <Box sx={{
            display: "flex",
            width: "100%",
            height: "100%",
        }}>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                width: {
                    xs: 375,
                    sm: 375,
                    md: 400,
                },
                margin: "auto",
            }}>
                {!success ? form : notificationSent}
            </Box>
        </Box>
    );
}