import { Navigate, useRoutes } from "react-router-dom";

import AuthClassicLayout from 'src/layouts/auth/classic';
import ForgotPasswordPage from "src/pages/auth/jwt/forgot-password";
import JwtLoginPage from "src/pages/auth/jwt/login";
import ResetPasswordPage from "src/pages/auth/jwt/reset-password";
import SelectOrganizationPage from "src/pages/auth/jwt/select-organization";
import { paths } from "../paths";
import { adminDashboardRoutes } from "./admin-dashboard";
import { contractRoutes } from "./contract";
import { couponRoutes } from "./coupon";
import { dashboardRoutes } from "./dashboard";
import { mainRoutes } from "./main";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: paths.root,
      element: (
        <AuthClassicLayout>
          <JwtLoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: paths.login,
      element: (
        <AuthClassicLayout>
          <JwtLoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: paths.listOrgs,
      element: (
        <AuthClassicLayout>
          <SelectOrganizationPage />
        </AuthClassicLayout>
      )
    },
    {
      path: paths.forgotPassword,
      element: (
          <ForgotPasswordPage />
      )
    },
    {
      path: paths.resetPassword,
      children: [
        { path: ':token', element: <ResetPasswordPage /> },
      ],
    },

    // Contract routes
    ...contractRoutes,

    // Coupon routes
    ...couponRoutes,

    // Dashboard routes
    ...dashboardRoutes,
    
    // Admin Dashboard
    ...adminDashboardRoutes,

    // Main routes
    ...mainRoutes,

    // No match 404
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
