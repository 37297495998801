import { paramCase } from 'src/utils/change-case';

import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  ADMIN_DASHBOARD: '/admin',
  COUPON: '/coupon',
  CONTRACT: '/contract'
};

// ----------------------------------------------------------------------

export const paths = {
  login: '/login',
  forgotPassword: '/forgot',
  resetPassword: '/reset',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  root: "/",
  listOrgs: "/list-organization",
  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id: string) => `/product/${id}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },
  post: {
    root: `/post`,
    details: (title: string) => `/post/${paramCase(title)}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  // AUTH
  auth: {
    login: `${ROOTS.AUTH}/login`,
    register: `${ROOTS.AUTH}/register`,
    verify: `${ROOTS.AUTH}/verify`,
    forgotPassword: `${ROOTS.AUTH}/forgot-password`,
    newPassword: `${ROOTS.AUTH}/new-password`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    mail: `${ROOTS.DASHBOARD}/mail`,
    chat: `${ROOTS.DASHBOARD}/chat`,
    blank: `${ROOTS.DASHBOARD}/blank`,
    kanban: `${ROOTS.DASHBOARD}/kanban`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    fileManager: `${ROOTS.DASHBOARD}/file-manager`,
    permission: `${ROOTS.DASHBOARD}/permission`,
    rootAdmin: ROOTS.ADMIN_DASHBOARD,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
      analytics: `${ROOTS.DASHBOARD}/analytics`,
      banking: `${ROOTS.DASHBOARD}/banking`,
      booking: `${ROOTS.DASHBOARD}/booking`,
      file: `${ROOTS.DASHBOARD}/file`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },
    machine: {
      new: `${ROOTS.DASHBOARD}/machine/new`,
      list: `${ROOTS.DASHBOARD}/machine/list`,
      credit: `${ROOTS.DASHBOARD}/machine/manual-credit`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/machine/${id}/edit`,
    },
    sales: {
      list: `${ROOTS.DASHBOARD}/sales/list`,
      details: (id: string) => `${ROOTS.DASHBOARD}/sales/${id}`,
    },
    statements: {
      list: `${ROOTS.DASHBOARD}/statements/list`,
      details: (id: string) => `${ROOTS.DASHBOARD}/statements/${id}`,
    },
    location: {
      new: `${ROOTS.DASHBOARD}/location/new`,
      list: `${ROOTS.DASHBOARD}/location/list`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/location/${id}/edit`,
    },
    telemetry: {
      list: `${ROOTS.DASHBOARD}/telemetry`,
      details: (id: number) => `${ROOTS.DASHBOARD}/telemetry/${id}/details`
    },
    visits: {
      list: `${ROOTS.DASHBOARD}/visits/list`,
      create: `${ROOTS.DASHBOARD}/visits/create`,
      view: (id: number) => `${ROOTS.DASHBOARD}/visits/view/${id}`,
    },
    product: {
      root: `${ROOTS.DASHBOARD}/product`,
      new: `${ROOTS.DASHBOARD}/product/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/product/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/product/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/product/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`,
      },
    },
    invoice: {
      root: `${ROOTS.DASHBOARD}/invoice`,
      new: `${ROOTS.DASHBOARD}/invoice/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/invoice/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}/edit`,
      },
    },
    post: {
      root: `${ROOTS.DASHBOARD}/post`,
      new: `${ROOTS.DASHBOARD}/post/new`,
      details: (title: string) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
      edit: (title: string) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
        edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
      },
    },
    job: {
      root: `${ROOTS.DASHBOARD}/job`,
      new: `${ROOTS.DASHBOARD}/job/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/job/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/job/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/job/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/job/${MOCK_ID}/edit`,
      },
    },
    tour: {
      root: `${ROOTS.DASHBOARD}/tour`,
      new: `${ROOTS.DASHBOARD}/tour/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/tour/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/tour/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}/edit`,
      },
    },
    coupons: {
      root: `${ROOTS.DASHBOARD}/coupons`,
      list: `${ROOTS.DASHBOARD}/coupons/list`,
      new: `${ROOTS.DASHBOARD}/coupons/new`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/coupons/${id}/edit`,
    },
    notifications: {
      root: `${ROOTS.DASHBOARD}/notifications`,
      list: `${ROOTS.DASHBOARD}/notifications/list`
    },
  },
  contract: {
    root: ROOTS.CONTRACT,
    sign: `${ROOTS.CONTRACT}/sign`,
    needSign: `${ROOTS.CONTRACT}/need-sign`
  },
  coupon: {
    root: ROOTS.COUPON,
    notFound: `${ROOTS.COUPON}/not-found`,
    success: `${ROOTS.COUPON}/success`,
    error: `${ROOTS.COUPON}/error`,
    use: `${ROOTS.COUPON}/:referenceId`,
  },
  administrativeDashboard: {
    root: ROOTS.ADMIN_DASHBOARD,
    consolidation: {
      list: `${ROOTS.ADMIN_DASHBOARD}/consolidation/list`,
    },
    organization: {
      list: `${ROOTS.ADMIN_DASHBOARD}/saas-organization/list`,
      edit: (id: string) => `${ROOTS.ADMIN_DASHBOARD}/saas-organization/${id}/edit`,
      contract: (id: string) => `${ROOTS.ADMIN_DASHBOARD}/saas-organization/${id}/contract`,
      create: `${ROOTS.ADMIN_DASHBOARD}/saas-organization/create`
    },
    statement: {
      list: `${ROOTS.ADMIN_DASHBOARD}/saas-statement/list`
    },
    saasMachine: {
      list: `${ROOTS.ADMIN_DASHBOARD}/saas-machine/list`,
      edit: (id: string) => `${ROOTS.ADMIN_DASHBOARD}/saas-machine/${id}/edit`,
    },
    saasSales: {
      list: `${ROOTS.ADMIN_DASHBOARD}/saas-sales/list`,
      details: (id: string) => `${ROOTS.ADMIN_DASHBOARD}/saas-sales/${id}`,
    },
    saasUser: {
      list: `${ROOTS.ADMIN_DASHBOARD}/saas-user/list`,
    }
  }
};

