import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { getPresignedURL, uploadImage } from "src/api/aws";
import { updateOrganization, useGetOrganization } from "src/api/organization";
import { useAuthContext } from "src/auth/hooks";
import { RHFAutocomplete, RHFTextField, RHFUploadAvatar } from "src/components/hook-form";
import FormProvider from "src/components/hook-form/form-provider";
import { useBoolean } from "src/hooks/use-boolean";
import { useResponsive } from "src/hooks/use-responsive";
import { ImageEntityTypeEnum } from "src/types/aws";
import { PixKeyOption, PixKeyTypeEnum } from "src/types/statements";
import { fData } from "src/utils/format-number";
import { IndentifyPixKeyType } from "src/utils/pixKeyIdentifier";
import * as Yup from 'yup';

type Props = {
    open: boolean;
    onClose: () => void;
}

const pixKeyTypeOptions: PixKeyOption[] = [
    {
        type: PixKeyTypeEnum.DOCUMENT,
        label: 'CPF/CNPJ',
        icon: 'solar:user-circle-broken',
    },
    {
        type: PixKeyTypeEnum.PHONE,
        label: 'Telefone',
        icon: 'solar:iphone-broken',
    },
    {   
        type: PixKeyTypeEnum.EMAIL,
        label: 'Email',
        icon: 'solar:letter-broken',
    },
    {
        type: PixKeyTypeEnum.RANDOM,
        label: 'Chave Aleatória',
        icon: 'solar:key-minimalistic-broken',
    }, 
]

export default function OrganizationQuickEdit({ open, onClose }: Props) {
    const { user } = useAuthContext();
    const { enqueueSnackbar } = useSnackbar();
    const { selectedOrganization } = useAuthContext();
    const [file, setFile] = useState<File | null>(null);
    const refresh = useBoolean(open);
    const [documentMask, setDocumentMask] = useState("99.999.999/9999-99");

    const isSaas = useBoolean(false);
    const lg = useResponsive('up', 'sm');

    const { 
        organization, isLoading
    }: any = useGetOrganization(refresh.value, selectedOrganization?.id);

    useEffect(() => {
        if(organization){
            const values = getValues();
            const fieldsNames = Object.keys(values) as unknown as any[];
    
            fieldsNames.forEach(field => {
                setValue(field, organization[field], { shouldValidate: true })
            });

            if(organization.cpfCnpj.length <= 14){
                setDocumentMask("999.999.999-99");
            }
        }

      }, [organization]);

    const organizationSchema: any = Yup.object().shape({
        id: Yup.number().nullable(),
        name: Yup.string().required('O nome é obrigatório!'),
        email: Yup.string().email('Precisa ser um E-mail válido').required('O email é obrigatório!'),
        phone: Yup.string().required('O telefone é obrigatório!'),
        whatsappNumber: Yup.string().required('O whatsapp é obrigatório!'),
        cpfCnpj: Yup.string(),
        logoPath: Yup.string().nullable(),
        description: Yup.string().required('A descrição é obrigatória!'),
        pixInfo: Yup.mixed<{key: string, keyType: string}>().optional().nullable(),
        credPreference: Yup.mixed<{optionId: number, optionDescription: string}>().optional().nullable(),
        planInfo: Yup.mixed<{planId: number, description: string}>().optional().nullable(),
    });
    
    const defaultValues = useMemo(
        () => ({
            id: organization?.id || null,
            name: organization?.name || '',
            email: organization?.email || '',
            phone: organization?.phone || '',
            whatsappNumber: organization?.whatsappNumber || '',
            cpfCnpj: organization?.cpfCnpj || '',
            logoPath: organization?.logoPath || null,
            description: organization?.description || '',
            pixInfo: organization?.pixInfo || null,
            credPreference: organization?.credPreference || null,
            planInfo: organization?.planInfo || null,
        }),
        [organization]
    );

    const methods = useForm({
        resolver: yupResolver(organizationSchema),
        defaultValues
    });

    const {
        reset,
        handleSubmit,
        getValues,
        setValue,
        formState: { isSubmitting },
      } = methods;

    const handleChangePixKeyType = (key: string): PixKeyTypeEnum => {
        const type = IndentifyPixKeyType(key);

        if(type === "cpf"){
            return PixKeyTypeEnum.DOCUMENT
        }

        if(type === "cnpj"){
            return PixKeyTypeEnum.DOCUMENT
        }

        if(type === "phone"){
            return PixKeyTypeEnum.PHONE
        }

        if(type === "email"){
            return PixKeyTypeEnum.EMAIL
        }

        if(type === "key"){
            return PixKeyTypeEnum.RANDOM;
        }

        return PixKeyTypeEnum.RANDOM;
    }

    useEffect(() => {
        if(user && user.isAdministrator){
            isSaas.onTrue();
        }else{
            isSaas.onFalse();
        }

    }, [user, isSaas]);

    const handleDrop = useCallback(
        (acceptedFiles: File[]) => {
            const file = acceptedFiles[0];

            if (file) {                
                setFile(file);
                setValue(`logoPath`, URL.createObjectURL(file), { shouldValidate: true });
            }
        },
        [setFile]
    );  

    const onSubmit = handleSubmit(async (data) => {
        try {
            await uploadOrganizationPhoto();
            await updateOrganization(data);
            reset();
            onClose();
            enqueueSnackbar('Organização atualizada com sucesso!');
            refresh.onTrue();
        } catch (error) {
            console.error(error);
        }
    });

    const uploadOrganizationPhoto = (async () => {
        try {
            if(!file) return;

            const { logoPath, preSignedUrl } = await getPresignedURL({
                fileName: file.name,
                imageEntity: ImageEntityTypeEnum.ORGANIZATION
            });

            const url = await uploadImage(file, preSignedUrl);

            setValue(`logoPath`, logoPath, { shouldValidate: true });
            setFile(null);
            return url;
        } catch (error) {
            console.error(error);
        }
    });


    if(isLoading || !organization || !organization.pixInfo){
        return <></>;
    }

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: { 
                    maxWidth: 'min-content',
                    justifyContent: 'flex-start' 
                },
            }}
        >
            <FormProvider methods={methods} onSubmit={onSubmit}>
                <DialogTitle>
                    Organização {organization.id ? ` - ${organization.id}` : ''}
                </DialogTitle>

                <DialogContent>
                    <Stack
                        direction="row"
                        spacing={4}
                        justifyContent="center"
                        flexWrap={lg ? "nowrap" : "wrap"}
                    >
                        <Box sx={{ mb: 5, minWidth: '300px', flex: 1 }}>
                            <RHFUploadAvatar
                                name="logoPath"
                                maxSize={3145728}
                                onDrop={handleDrop}
                                sx={{
                                    minWidth: '180px',
                                    minHeight: '180px',
                                }}
                                helperText={
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            mt: 3,
                                            mx: 'auto',
                                            display: 'block',
                                            textAlign: 'center',
                                            color: 'text.disabled',
                                        }}
                                    >
                                        Formatos: *.jpeg, *.jpg, *.png, *.gif
                                        <br /> Tamanho máx: {fData(3145728)}
                                    </Typography>
                                }
                            />
                        </Box>
                        <Box
                            rowGap={3}
                            columnGap={2}
                            display="grid"
                            gridTemplateColumns={{
                                xs: 'repeat(1, minmax(250px, 1fr))',
                                sm: 'repeat(2, minmax(250px, 1fr))',
                            }}
                            sx={{
                                flex: 1
                            }}
                        >
                            <Box sx={{ display: { xs: 'none', sm: 'block' } }}> 
                                <Typography variant="subtitle2">
                                    Informações
                                </Typography>
                            </Box>
                            <Box sx={{ display: { xs: 'none', sm: 'block' } }} />
                            <RHFTextField name="name" label="Nome" disabled/>
                            <RHFTextField name="cpfCnpj" label="CPF/CNPJ" masked mask={documentMask} disabled/>
                            <RHFTextField name="email" label="Email" disabled />
                            <RHFTextField name="phone" label="Telefone" masked mask="(99) 99999-9999" disabled/> 
                            <RHFTextField name="whatsappNumber" label="Whatsapp" masked mask="(99) 99999-9999" disabled/>
                            <RHFTextField name="description" label="Descrição" disabled />
                            <RHFTextField name="planInfo.description" label="Plano" disabled />
                            <RHFTextField name="credPreference.optionDescription" label="Tipo de resgate" disabled />

                            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                <Typography variant="subtitle2">
                                    Pix
                                </Typography>
                            </Box>
                            <Box sx={{ display: { xs: 'none', sm: 'block' } }} />

                            <RHFTextField
                                label="Chave PIX" 
                                name="pixInfo.key"
                                disabled
                            />
                            <RHFAutocomplete
                                id="teste"
                                label="Tipo"
                                options={pixKeyTypeOptions}
                                value={pixKeyTypeOptions.filter(options => options.type == organization.pixInfo.keyType)[0]}
                                name="pixInfo.keyType"
                                getOptionLabel={(option) => { 
                                    if(typeof option === 'string') return option;
                                    return option.label;
                                }}
                                disabled
                            />
                        </Box>
                    </Stack>
                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" onClick={onClose}>
                        Cancelar
                    </Button>

                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                        Atualizar
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
        </Dialog>
    )
}