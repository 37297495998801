
import { useMemo } from 'react';
import { ILoginRequest, ILoginResponse, IUpdateTokenRequest } from 'src/types/auth';
import axiosInstance, { accessEndpoints } from 'src/utils/axios';
import { useCustomApiFetcher } from 'src/utils/fetcherCustomHook';

export async function loginRequest(data: ILoginRequest): Promise<ILoginResponse> {
  const url = accessEndpoints.auth.login;

  const request: any = await axiosInstance.request({
    url: url,
    method: `post`,
    data,
    responseType: `json`
  });

  if(request.status >= 400){
    throw(request.data);
  }
  
  return request.data;
}

export async function updateTokenRequest(data: IUpdateTokenRequest): Promise<ILoginResponse> {
  const url = accessEndpoints.auth.refresh;

  const request: any = await axiosInstance.request({
    url: url,
    method: `post`,
    data,
    responseType: `json`
  });

  if(request.status >= 400){
    throw(request.data);
  }
  
  return request.data;
}

export async function forgotPasswordRequest(data: {email: string}): Promise<any> {
  const url = accessEndpoints.auth.forgot;

  const request: any = await axiosInstance.request({
    url: url,
    method: `post`,
    data,
    responseType: `json`
  });

  if(request.status >= 400){
    throw(request.data);
  }
  
  return request.data;
}

export function useValidateResetPasswordTokenRequest(request: {token: string}) {
  const url = accessEndpoints.auth.reset(request.token);

  const { data, error, isLoading } = useCustomApiFetcher(
      url,
      request,
      `get`,
      false
  );

  const memoizedValue = useMemo(
    (): {userId: number, isLoading: boolean, error: any} => ({
      userId: data?.userId,
      isLoading,
      error
    }),
    [data, error, isLoading]
  );

  return { memoizedValue };
}

export async function changePassword(data: {token: string, userId: number, password: string, confirmedPassword: string}): Promise<any> {
  const url = accessEndpoints.auth.changePassword;

  const request: any = await axiosInstance.request({
    url: url,
    method: `post`,
    data,
    responseType: `json`
  });

  if(request.status >= 400){
    throw(request.data);
  }
  
  return request.data;
}