import { useCallback, useEffect, useMemo, useReducer } from 'react';

import { loginRequest } from 'src/api/auth';
import { saveUserToken } from 'src/api/contactFirebaseToken';
import axios, { accessEndpoints, endpoints } from 'src/utils/axios';
import { ActionMapType, AuthStateType, AuthUserType, Organization } from '../../types';
import { AuthContext } from './auth-context';
import { eraseCookie, getCookie, isValidToken, setCookie, setOrganization, setSession } from './utils';

export enum RedirectTo {
  LOGIN = "",
  DASHBOARD = "dashboard",
  ADMIN_DASHBOARD = "admin_dashboard",
  LIST_ORGANIZATION = "list-organization",
  OPERATOR_INIT = "dashboard/machine"
}

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
  LIST_ORGANIZATIONS = 'LIST_ORGANIZATIONS',
  CHECK_DESTINATION = 'CHECK_DESTINATION',
  SELECT_ORGANIZATION= 'SELECT_ORGANIZATION',
  SAVE_FIREBASE_TOKEN = 'SAVE_FIREBASE_TOKEN'
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
    organizations: Organization[] | undefined;
    selectedOrganization: Organization | undefined;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
    organizations: Organization[] | undefined;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
    organizations: Organization[] | undefined;
  };
  [Types.LOGOUT]: {
    user: undefined;
    organizations: Organization[] | undefined;
    redirectTo: string;
    selectedOrganization?: Organization;
  };
  [Types.LIST_ORGANIZATIONS]: {
    organizations: Organization[] | undefined;
  };
  [Types.CHECK_DESTINATION]: {
    user: undefined;
    organizations: Organization[] | undefined;
    redirectTo: string;
  };
  [Types.SELECT_ORGANIZATION]: {
    selectedOrganization: Organization;
    role: string;
    permissions: string[];
  };
  [Types.SAVE_FIREBASE_TOKEN]: {
    selectedOrganization: Organization;
    role: string;
    permissions: string[];
  };
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const initialState: AuthStateType = {
  user: null,
  loading: true,
  organizations: undefined,
  redirectTo: "/",
  selectedOrganization: undefined,
  role: undefined,
  permissions: undefined,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
      organizations: undefined,
      selectedOrganization: action.payload.selectedOrganization,
      permissions: undefined,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
      permission: undefined,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LIST_ORGANIZATIONS) {
    return {
      ...state,
      organizations: action.payload.organizations,
      permissions: undefined,
    };
  }
  if (action.type === Types.CHECK_DESTINATION) {
    return {
      ...state,// TODO adicionar destino
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
      organizations: undefined,
      selectedOrganization: undefined,
      permissions: undefined
    };
  }
  if (action.type === Types.SELECT_ORGANIZATION) {
    return {
      ...state,
      selectedOrganization: action.payload.selectedOrganization,
      role: action.payload.role,
      permissions: action.payload.permissions,
    };
  }
  if (action.type === Types.SAVE_FIREBASE_TOKEN) {
    return {
      ...state,
      selectedOrganization: action.payload.selectedOrganization,
      role: action.payload.role,
      permissions: action.payload?.permissions,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';
const STORAGE_SELECTED_ORGANIZATION = 'selectOrgnaization';
const USER_FIREBASE_TOKEN = 'firebaseToken';
const REDIRECT_TO_URL = 'returnTo';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    
    try {
      const accessToken = getCookie(STORAGE_KEY);
      const refreshToken = getCookie(REFRESH_TOKEN_KEY);
      
      if ((accessToken && isValidToken(accessToken)) || (refreshToken && isValidToken(refreshToken))) {
        setSession(accessToken, refreshToken);
        
        const res = await axios.get(accessEndpoints.auth.me);
        
        const user = res.data;

        let selectedOrganizationStorage = getCookie(STORAGE_SELECTED_ORGANIZATION);
        const selectedOrganization = selectedOrganizationStorage ? JSON.parse(selectedOrganizationStorage) : undefined;
        selectOrganization(selectedOrganization);

        dispatch({
          type: Types.INITIAL,
          payload: {
            user: {
              ...user,
              accessToken,
            },
            organizations: undefined,
            selectedOrganization,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
            organizations: undefined,
            selectedOrganization: undefined,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
          organizations: undefined,
          selectedOrganization: undefined
        },
      });
    }
  }, []);

  const selectOrganization = useCallback(async (organization: Organization) => {
    
    if(!organization){
      return;
    }

    //TODO Recarregar as permissões?
    if (organization?.id){
      sessionStorage.setItem(STORAGE_SELECTED_ORGANIZATION, JSON.stringify(organization));
      setCookie(STORAGE_SELECTED_ORGANIZATION, JSON.stringify(organization), 365);
      state.selectedOrganization = organization;
    }

    setOrganization(organization);
    
    const roleAndPermissions = await getRoleAndPermissions(organization);
    const { role, permissions } = roleAndPermissions;

    if(organization.id == -1){
      dispatch({
        type: Types.SELECT_ORGANIZATION,
        payload: {
          selectedOrganization: organization,
          role,
          permissions,
        }
      });

      return;
    }
    
    dispatch({
      type: Types.SELECT_ORGANIZATION,
      payload: {
        selectedOrganization: organization,
        role,
        permissions,
      }
    });

  }, [state]);

  const removeRedirectTo = useCallback(async () => {
    sessionStorage.removeItem(REDIRECT_TO_URL);
  }, [initialize]);

  const setRedirectTo = useCallback(async (returnTo: string) => {
    sessionStorage.setItem(REDIRECT_TO_URL, returnTo);
  }, [initialize]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    const data = {
      usernameOrEmail: email.trim(),
      password,
    };

    const res = await loginRequest(data);

    let token = res.token;
    let refreshToken = res.refreshToken;
    let supportToken = res.supportToken;
    let user: any;

    user = res.user;

    setSession(token, refreshToken);

    dispatch({
      type: Types.LOGIN,
      payload: {
        user: {
          ...user,
          accessToken: token,
          supportToken,
        },
        organizations: undefined,
      },
    });

    return user;
  }, []);

  // REGISTER
  const register = useCallback(
    async (email: string, password: string, firstName: string, lastName: string) => {
      const data = {
        email,
        password,
        firstName,
        lastName,
      };

      const res = await axios.post(accessEndpoints.auth.register, data);

      const { accessToken, user } = res.data;

      sessionStorage.setItem(STORAGE_KEY, accessToken);
      setCookie(STORAGE_KEY, accessToken, 365);

      dispatch({
        type: Types.REGISTER,
        payload: {
          user: {
            ...user,
            accessToken,
          },
          organizations: undefined,
        },
      });
    },
    []
  );

  // LOGOUT
  const logout = useCallback(async () => {
    await setSession(undefined, undefined);
    await sessionStorage.removeItem(STORAGE_SELECTED_ORGANIZATION);
    await sessionStorage.removeItem(STORAGE_KEY);
    await sessionStorage.removeItem(REFRESH_TOKEN_KEY);
    await eraseCookie(STORAGE_SELECTED_ORGANIZATION);
    await dispatch({
      type: Types.LOGOUT,
      payload: {
        user: undefined,
        organizations: undefined,
        redirectTo: RedirectTo.LOGIN,
        selectedOrganization: undefined,
      }
    });
  }, []);


  // LIST ORGANIZATIONS
  const listOrganization = useCallback(async () => {
    try {

      // TODO: Entender por que o state de user não atualiza ao realizar o dispatch no logout.
      const key = sessionStorage.getItem(STORAGE_KEY);
      if(!key){
        return;
      }

      if (!state?.user?.id){
        return;
      }

      if (state?.organizations) {
        return;
      }

      const res = await axios.get(
        `${endpoints.organizations.list}`
      );

      dispatch({
        type: Types.LIST_ORGANIZATIONS,
        payload: {
          organizations: res.data,
        }
      });

      return res.data;
    } catch (error) {
      dispatch({
        type: Types.LIST_ORGANIZATIONS,
        payload: {
          organizations: undefined,
        }
      });
    }
  }, [state.user, state?.organizations]);

  const checkDefaultOrganization = useCallback(async () => {
    if (!state.organizations || state.organizations.length <= 0) {
       throw Error ("Usuário não possui acesso a nenhuma organização");
    }

    if (state.organizations.length >= 2) {
      return RedirectTo.LIST_ORGANIZATION;
    }

    const organization = state.organizations[0];

    selectOrganization(organization);

    if (organization.id === -1) {
      return RedirectTo.ADMIN_DASHBOARD;
    }

    return RedirectTo.DASHBOARD;

  }, [state, selectOrganization]);

  const saveFirebaseToken = useCallback(async (request: any) => {
    if(sessionStorage.getItem(USER_FIREBASE_TOKEN)){
      return;
    }

    await saveUserToken(request);
    sessionStorage.setItem(USER_FIREBASE_TOKEN, request.token);

  }, []);

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      organizations: state.organizations,
      redirectTo: state.redirectTo,
      selectedOrganization: state.selectedOrganization,
      role: state.role,
      permissions: state.permissions,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      logout,
      listOrganization,
      checkDefaultOrganization,
      selectOrganization,
      saveFirebaseToken,
      setRedirectTo,
      removeRedirectTo
    }),
    [
      login,
      logout,
      register,
      listOrganization,
      checkDefaultOrganization,
      selectOrganization,
      saveFirebaseToken,
      setRedirectTo,
      removeRedirectTo,
      state.user,
      state.organizations,
      status,
      state.redirectTo,
      state.selectedOrganization,
      state.role,
      state.permissions,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;

  async function getRoleAndPermissions(organization: Organization) {
    const endPoint = accessEndpoints.auth.permissions.replace(":organizationId", `${organization?.id}`);
    const res = await axios.get(endPoint);
    const data = res?.data;
    return data;
  }
}
